
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import { toDate } from '../../utils/common'
import store from '../../store'
@Options({
  components: {}
})
export default class rulingInfo extends Vue {
  toDate = toDate;
  d = {};
  dialogVisible = false;
  loading = false;
  created () {
    this.loading = true
    api
      .getJudgmentDocCheckDetail({
        id: this.$route.params.id,
        ...store.getters['company/get']
      })
      .then((data: any) => {
        this.d = data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
